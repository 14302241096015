var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('FilterStudent',{attrs:{"callbackSelect":_vm.selectFilter}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('FilterClassroom',{attrs:{"classroom":_vm.classroom,"callbackSelect":_vm.selectClassroom}})],1)],1),_c('v-simple-table',{attrs:{"fixed-header":"","height":"520px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.prop.no")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.prop.fullname")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.name.classroom")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.name.school")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.prop.status")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.prop.totalAttempt")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.prop.totalSubmission")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.prop.totalStar")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.prop.totalScore")))])])]),_c('tbody',{staticClass:"table"},[((_vm.data ? _vm.data : _vm.filterStudents).length == 0)?_c('tr',{staticClass:"text-center"},[_c('td',{staticClass:"grey--text",attrs:{"colspan":"9"}},[_vm._v(" "+_vm._s(_vm.$t("api.response.no_data"))+" ")])]):_vm._e(),_vm._l((_vm.data ? _vm.data : _vm.filterStudents),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                name: 'Page'+_vm.role+'ClassroomStudentRead',
                params: {
                  parentId: item.Student.Classroom.id,
                  id: item.Student.id,
                },
              }}},[_vm._v(_vm._s(item.name))])],1),_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                name: 'Page'+_vm.role+'ClassroomRead',
                params: {
                  id: item.Student.Classroom.id,
                },
              }}},[_vm._v(" "+_vm._s(item.Student.Classroom.name)+" ")])],1),_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                name: 'Page'+_vm.role+'SchoolRead',
                params: {
                  id: item.Student.School.id,
                },
              }}},[_vm._v(" "+_vm._s(item.Student.School.name)+" ")])],1),_c('td',[_c('ChipStudentStatus',{attrs:{"data":item}})],1),_c('td',[_vm._v(" "+_vm._s(item.Attempt.length)+" ")]),_c('td',[_vm._v(_vm._s(item.totalAttempt))]),_c('td',[_vm._v(_vm._s(item.totalStar)+" ★")]),_c('td',[_vm._v(_vm._s(item.totalScore))])])})],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }