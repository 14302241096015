<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6">
        <FilterStudent :callbackSelect="selectFilter" />
      </v-col>
      <v-col cols="12" sm="6">
        <FilterClassroom
          :classroom="classroom"
          :callbackSelect="selectClassroom"
        />
      </v-col>
    </v-row>
    <v-simple-table fixed-header height="520px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{ $t("model.prop.no") }}</th>
            <th class="text-left">{{ $t("model.prop.fullname") }}</th>
            <th class="text-left">{{ $t("model.name.classroom") }}</th>
              <th class="text-left">{{ $t("model.name.school") }}</th>
            <th class="text-left">{{ $t("model.prop.status") }}</th>
            <th class="text-left">{{ $t("model.prop.totalAttempt") }}</th>
            <th class="text-left">{{ $t("model.prop.totalSubmission") }}</th>
            <th class="text-left">{{ $t("model.prop.totalStar") }}</th>
            <th class="text-left">{{ $t("model.prop.totalScore") }}</th>
          </tr>
        </thead>
        <tbody class="table">
          <tr
            class="text-center"
            v-if="(data ? data : filterStudents).length == 0"
          >
            <td colspan="9" class="grey--text">
              {{ $t("api.response.no_data") }}
            </td>
          </tr>
          <tr
            v-for="(item, index) in data ? data : filterStudents"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <td>
              <router-link
                class="text-decoration-none"
                :to="{
                  name: 'Page'+role+'ClassroomStudentRead',
                  params: {
                    parentId: item.Student.Classroom.id,
                    id: item.Student.id,
                  },
                }"
                >{{ item.name }}</router-link
              >
            </td>
            <td>
              <router-link
                class="text-decoration-none"
                :to="{
                  name: 'Page'+role+'ClassroomRead',
                  params: {
                    id: item.Student.Classroom.id,
                  },
                }"
              >
                {{ item.Student.Classroom.name }}
              </router-link>
            </td>
             <td>
              <router-link
                class="text-decoration-none"
                :to="{
                  name: 'Page'+role+'SchoolRead',
                  params: {
                    id: item.Student.School.id,
                  },
                }"
              >
                {{ item.Student.School.name }}
              </router-link>
            </td>
            <td>
              <ChipStudentStatus :data="item"></ChipStudentStatus>
            </td>
            <td> {{item.Attempt.length}} </td>
            <td>{{ item.totalAttempt }}</td>
            <td>{{ item.totalStar }} ★</td>
            <td>{{ item.totalScore }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import ChipStudentStatus from "@/components/Bread/Event/Student/ChipStudentStatus";
import FilterStudent from "@/components/Scoreboard/FilterStudent";
import FilterClassroom from "@/components/Scoreboard/FilterClassroom";
export default {
  props: ["filterStudents", "classroom","role"],
  components: {
    ChipStudentStatus,
    FilterStudent,
    FilterClassroom,
  },
  data: () => ({
    data: null,
    filterBy: 2,
  }),

  methods: {
    selectFilter(filterBy) {
      this.filterBy = filterBy;
      if (this.filterBy == 1) {
        (this.data ? this.data : this.filterStudents).sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
      } else if (this.filterBy == 2) {
        (this.data ? this.data : this.filterStudents)
          .reverse()
          .sort((a, b) => (a.totalScore < b.totalScore ? 1 : -1));

            (this.data ? this.data : this.filterStudents)
          .reverse()
          .sort((a, b) => (a.status < b.status ? 1 : -1));
      }
    },
    selectClassroom(id) {
      this.data = null;
      if (id != 0) {
        this.data = this.filterStudents.filter(
          (e) => e.Student.Classroom.id === id
        );
      }
      this.selectFilter(this.filterBy);
    },
  },
  created() {
    if (this.$route.query.classroom) {
      this.selectClassroom(parseInt(this.$route.query.classroom));
    }
  },
};
</script>

<style>
.v-data-table__wrapper::-webkit-scrollbar {
  display: none;
}
.v-data-table__wrapper {
  -ms-overflow-style: none;
}
</style>