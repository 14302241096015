<template>
  <v-container>
    <!-- <v-btn @click="fetch" :loading="api.isLoading">Fetch</v-btn> -->
    <div v-if="api.loading" class="text-center pa-5">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
   
    <div v-else>
      <div ref="printMe">
        <ReportEvent :group="group" :eventCode="eventCode"></ReportEvent>
        <ReportStat :group="group" :eventCode="eventCode"></ReportStat>
        <ReportSubmission :group="group" :isUnique="true" :eventCode="eventCode"></ReportSubmission>
        <ReportSubmission :group="group" :eventCode="eventCode"></ReportSubmission>
        <div class="text-center">Powered by hamochi.my</div>
      </div>
      <div class="d-flex my-3">
        <v-spacer></v-spacer>
        <v-btn @click="printThis" :loading="loading" color="primary">
          {{ $t("action.download") }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
// import TableTrend from "@/components/EventAnalysis/TableTrend";
import ReportEvent from "@/components/Moderator/Event/Report/Event";
import ReportStat from "@/components/Moderator/Event/Report/Stat";
import ReportSubmission from "@/components/Moderator/Event/Report/Submission";

//BOC: [print]
import VueHtml2Canvas from "vue-html2canvas";
import Vue from "vue";
Vue.use(VueHtml2Canvas);
//EOC

import { mapState } from "vuex";
export default {
  components: {
    // TableTrend,
    ReportEvent,
    ReportStat,
    ReportSubmission,
  },
  props: ["group","eventCode"],
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: null,
    event: [],
    school: [],
    activity: [],
    isFetchActivity: false,
    dataMutated: {},
    niro: [],
    days: [],
    form: {
      schoolId: null,
      classroomIds: [],
      startDate: null,
      endDate: null,
    },
    //BOC:[platforms]
    platforms: {
      uid: {
        both: 0,
        full: 0,
        lite: 0,
      },
      visit: {
        both: {
          total: 0,
          full: 0,
          lite: 0,
        },
        full: 0,
        lite: 0,
      },
    },
    //EOC
    //
    tab: 1,
    isModerator: false,
  }),
  created() {},
  mounted() {
    //
  },
  methods: {
    async printThis() {
      this.loading = true;
      const el = this.$refs.printMe;
      const options = {
        type: "dataURL",
        useCORS: true,
      };
      this.output = await this.$html2canvas(el, options);
      this.downloadImg();
    },
    downloadImg() {
      var link = document.createElement("a");
      link.download = "Hamochi Report.png";
      link.href = this.output;
      link.click();
      this.loading = false;
    },
  },
};
</script>