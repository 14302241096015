<template>
  <div>
    <!-- BOC:[header] -->
    <div class="d-flex pb-6 text-right">
      <v-spacer></v-spacer>
      <div class="pr-3">
        <v-btn
          color="primary"
          large
          :to="{
            name: 'PageModeratorEventClassroomOnEventAssignOtherSchool',
            params: { parentId: parent.id, parent: parent },
          }"
        >
          + {{$t("action.assign_other_school")}}
        </v-btn>
      </div>
      <div class="pr-3">
        <v-btn
          color="primary"
          large
          :to="{
            name: 'PageModeratorEventClassroomOnEventAssignSchool',
            params: { parentId: parent.id, parent: parent },
          }"
        >
          + {{$t("action.assign_school_year")}}
        </v-btn>
      </div>
      <div class="pr-3">
        <v-btn
          color="primary"
          large
          :to="{
            name: 'PageModeratorEventClassroomOnEventAdd',
            params: { parentId: parent.id, parent: parent },
          }"
        >
          + {{$t("action.assign_classroom")}}
        </v-btn>
      </div>
    </div>
    <!-- EOC -->
    <!-- BOC:[table] -->
    <BreadBrowseTable
      role="Moderator"
      :model="model"
      :url="`${$api.servers.moderator}/v1/event/${parent.id}/classroom`"
      :isSearchable="true"
      :parent="parent"
      :modelParent="modelParent"
    ></BreadBrowseTable>
    <!-- EOC -->
  </div>
</template>

<script>
//BOC:[model]
import modelParent from "@/models/items/event";
import model from "@/models/items/classroomOnEvent";
//EOC
//BOC:[table]
import BreadBrowseTable from "@/components/Bread/BreadBrowseTable";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //BOC:[table]
    BreadBrowseTable,
    //EOC
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: ["role", "parent"],
  data: () => ({
    //BOC:[model]
    modelParent: modelParent,
    model: model,
    //EOC
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>