<template>
  <div>
    <v-tabs
      v-model="tab"
    >
      <v-tab
        key="students"
      >
       Students
      </v-tab>
      <v-tab
        key="users"
      >
       Users
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        key="students"
      >
      <PageDeliveryInfoStudent
      :url="`${$api.servers.sso}/api/v1/en/moderator/event/${parent.id}/institution/deliveryInfo`"
    ></PageDeliveryInfoStudent>
      </v-tab-item>
      <v-tab-item
        key="users"
      >
      <PageDeliveryInfoUser
      :url="`${$api.servers.sso}/api/v1/en/moderator/event/${parent.id}/user/deliveryInfo`"
    ></PageDeliveryInfoUser>
      </v-tab-item>
    </v-tabs-items>
   
  </div>
</template>

<script>
//BOC:[table]
//import PageParticipant from "@/components/Bread/Event/Student/PageParticipant";
//EOC
import { mapState } from "vuex";
import PageDeliveryInfoStudent from "../components/Moderator/Event/DeliveryInfo/PageDeliveryInfoStudent.vue";
import PageDeliveryInfoUser from "../components/Moderator/Event/DeliveryInfo/PageDeliveryInfoUser.vue";
export default {
  components: {
    PageDeliveryInfoUser,
    PageDeliveryInfoStudent
    //BOC:[table]
    // PageParticipant,
    //EOC
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: ["role", "parent"],
  data: () => ({
    tab: 'students',
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>