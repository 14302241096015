<template>
  <div>
    <div class="d-flex my-3">
      <v-spacer></v-spacer>
      <v-btn @click="printThis" :loading="loading" color="primary" class="mr-3">
        Download Image
      </v-btn>
      <JsonExcel
        name="event_report.xls"
        :worksheet="'Year ' + schoolYear"
        :data="printData"
      >
        <v-btn color="primary" dark> Download Excel </v-btn>
      </JsonExcel>
    </div>
    <div v-if="!api.isLoading" ref="printMe">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">School Year</th>
              <th class="text-left">Students</th>
              <th class="text-left">Participants</th>
              <th class="text-left">Finishers</th>
              <th class="text-left">Completed</th>
              <th class="text-left">Attendance</th>
              <th class="text-left">Total Submissions</th>
              <th class="text-left">Total Questions</th>
              <th class="text-left">Total Corrects</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item) in data" :key="item.id">
              <td>Year {{ item.schoolYear }}</td>
              <td>{{ item.students }}</td>
              <td>{{ item.participants }}</td>
              <td>{{ item.finishers }}</td>
              <td>{{ item.completed }}%</td>
              <td>{{ item.attendance }}%</td>
              <td>{{ item.submissions }}</td>
              <td>{{ item.questions }}</td>
              <td>{{ item.corrects }}</td>
              <td class="text-left" width="120px">
                <ProgressBar
                  :completed="item.completed"
                  :attendance="item.attendance"
                ></ProgressBar>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <!-- BOC:[loader] -->
    <ALoader :isLoading="api.isLoading"></ALoader>
    <!-- EOC -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProgressBar from "./ProgressBar.vue";

import JsonExcel from "vue-json-excel";
//BOC: [print]
import VueHtml2Canvas from "vue-html2canvas";
import Vue from "vue";
Vue.use(VueHtml2Canvas);
//EOC
export default {
  components: {
    ProgressBar,
    JsonExcel,
  },
  computed: mapState({
    //
  }),
  props: ["eventCode"],
  data: () => ({
    data: [],
    printData: [],
    schoolYear: null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    loading: false,
  }),
  created() {
    //BOC:[api]
    this.api.url = `${this.$api.servers.event}/api/v1/zh/moderator/event/report/matrix`;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.data = resp;
      this.api.isLoading = false;
      this.printData = this.data.map((obj, index) => {
        return {
          No: index + 1,
          Students: obj.students,
          Participants: obj.participants,
          Finishers: obj.finishers,
          "Completed(%)": obj.completed,
          "Attendance(%)": obj.attendance,
          "Total Submissions": obj.submissions,
          "Total Questions": obj.questions,
          "Total Corrects": obj.corrects,
        };
      });
    };
    //EOC
  },
  mounted() {
    this.api.params = { eventCode: this.eventCode };
    this.$api.fetch(this.api);
  },
  methods: {
    async printThis() {
      this.loading = true;
      const el = this.$refs.printMe;
      const options = {
        type: "dataURL",
        useCORS: true,
      };
      this.output = await this.$html2canvas(el, options);
      this.downloadImg();
    },
    downloadImg() {
      var link = document.createElement("a");
      link.download = "Hamochi Report.png";
      link.href = this.output;
      link.click();
      this.loading = false;
    },
  },
};
</script>