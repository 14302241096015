var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex pb-6 text-right"},[_c('v-spacer'),_c('div',{staticClass:"pr-3"},[_c('v-btn',{attrs:{"color":"primary","large":"","to":{
          name: 'PageModeratorEventClassroomOnEventAssignOtherSchool',
          params: { parentId: _vm.parent.id, parent: _vm.parent },
        }}},[_vm._v(" + "+_vm._s(_vm.$t("action.assign_other_school"))+" ")])],1),_c('div',{staticClass:"pr-3"},[_c('v-btn',{attrs:{"color":"primary","large":"","to":{
          name: 'PageModeratorEventClassroomOnEventAssignSchool',
          params: { parentId: _vm.parent.id, parent: _vm.parent },
        }}},[_vm._v(" + "+_vm._s(_vm.$t("action.assign_school_year"))+" ")])],1),_c('div',{staticClass:"pr-3"},[_c('v-btn',{attrs:{"color":"primary","large":"","to":{
          name: 'PageModeratorEventClassroomOnEventAdd',
          params: { parentId: _vm.parent.id, parent: _vm.parent },
        }}},[_vm._v(" + "+_vm._s(_vm.$t("action.assign_classroom"))+" ")])],1)],1),_c('BreadBrowseTable',{attrs:{"role":"Moderator","model":_vm.model,"url":((_vm.$api.servers.moderator) + "/v1/event/" + (_vm.parent.id) + "/classroom"),"isSearchable":true,"parent":_vm.parent,"modelParent":_vm.modelParent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }