<template>
  <v-container>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
     <!-- BOC:[header] -->
     <div class="d-flex pb-6 text-right">
      <v-spacer></v-spacer>
      <div>
      <JsonExcel name="delivery_student_info.xls" worksheet="Hamochi" :data="printData">
        <v-btn color="primary" dark>
          {{ $t("action.download") }}
        </v-btn>
      </JsonExcel>
    </div>
    </div>
    <!-- EOC -->
  
    <v-card v-if="!api.isError" :loading="api.isLoading">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">Name</th>
              <th class="text-left">Address</th>
              <th class="text-left">Postcode</th>
              <th class="text-left">Town</th>
              <th class="text-left">State</th>
              <th class="text-left">Full Address</th>
              <th class="text-left">Admin name</th>
              <th class="text-left">Admin mobile</th>
              <th class="text-left">Finisher Count</th>
            </tr>
          </thead>
          <tbody class="text-uppercase">
            <tr v-for="(item, i) in data" :key="item.id">
              <td>{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.address }}</td>
              <td>{{ item.postcode }}</td>
              <td>{{ item.town }}</td>
              <td>{{ item.state }}</td>
              <td>{{item.name}}<br/>{{ item.address }}<br/>{{ item.postcode }} {{ item.town }}<br/>{{ item.state }}<br/><span v-if="item.Account && item.Account.Owner">{{ item.Account.Owner.User.name }}<br/>{{ item.Account.mobile }}</span></td>
              <td v-if="item.Account.Owner">
                {{ item.Account.Owner.User.name }}
              </td>
              <td v-else>-</td>
              <td v-if="item.Account">{{ item.Account.mobile }}</td>
              <td v-else>-</td>
              <td>{{ item._count }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import JsonExcel from "vue-json-excel";
export default {
  props: ["url"],
  components: {
    JsonExcel,
  },
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    printData: [],
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.printData = this.data.map((obj) => {
        return { 'Name': obj.name,
        'Address':obj.address,
        'Town': obj.town,
        'Postcode': "'"+obj.postcode,
        'State': obj.state,
        'Admin Name':obj.Account.Owner ? obj.Account.Owner.User.name : '-',
        'Admin Mobile': obj.Account.mobile || '-',
        'Finisher Count': obj._count,
       };
      });
    };
    //EOC
  },
  methods: {
    fetch() {
      this.api.url = this.url;
      this.$api.fetch(this.api);
    },
  },
  beforeMount() {
    this.fetch();
  },
};
</script>

<style>
</style>