<template>
  <div>
    <!-- BOC:[header] -->
    <v-row class="mb-3">
      <v-col class="text-right">
        <v-btn
          color="primary"
          large
          :loading="api.isLoading"
          @click="sendLeague()"
        >
          Send League Reward
        </v-btn>
        <v-btn
          color="primary"
          large
          :loading="adultApi.isLoading"
          @click="sendLeagueToAdult()"
          class="ml-2"
        >
          Send League Reward To Adult
        </v-btn>
      </v-col>
    </v-row>
    <!-- EOC -->
    <!-- BOC:[table] -->
    <BreadBrowseTable
      role="Moderator"
      :model="model"
      :url="`${$api.servers.event}/api/v2/en/moderator/event/${parent.id}/player`"
      :isSearchable="true"
      :parent="parent"
      :modelParent="modelParent"
      ref="bread_table"
    ></BreadBrowseTable>
    <!-- EOC -->
  </div>
  </template>
  
  <script>
    //BOC:[model]
    import modelParent from '@/models/items/event'
    import model from '@/models/items/eventPlayer'
    //EOC
    //BOC:[table]
    import BreadBrowseTable from '@/components/Bread/BreadBrowseTableV2'
    //EOC
    import { mapState } from 'vuex'
    export default {
      components:{
        //BOC:[table]
        BreadBrowseTable,
        //EOC
      },
      computed: mapState({
        school: state => state.school.data,
      }),
      props:[
        'role',
        'parent',
      ],
      data:()=>({
        //BOC:[model]
        modelParent:modelParent,
        model: model,
        //EOC
        //BOC:[api]
      api:{
        isLoading:false,
        isError:false,
        error:null,
        url:null,
      },
      adultApi:{
        isLoading:false,
        isError:false,
        error:null,
        url:null,
      },
      //EOC
      }),
      created() {
         //BOC:[url]
      this.api.url = `${this.$api.servers.event}/api/v1/en/moderator/event/${this.parent.id}/mail/sendLeague`
      //EOC
      //BOC:[api]
      this.api.method = 'get'
      this.api.callbackReset = () => {
        this.api.isLoading = true
        this.api.isError = false
      }
      this.api.callbackError = (e) => {
        this.api.isLoading = false
        this.api.isError = true
        this.api.error = e
      }
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false
        this.$refs['bread_table'].fetch()
        console.log(resp)
      }

      //BOC:[url]
      this.adultApi.url = `${this.$api.servers.event}/api/v1/en/moderator/event/${this.parent.id}/mail/sendLeagueToAdult`
      //EOC
      //BOC:[api]
      this.adultApi.method = 'get'
      this.adultApi.callbackReset = () => {
        this.adultApi.isLoading = true
        this.adultApi.isError = false
      }
      this.adultApi.callbackError = (e) => {
        this.adultApi.isLoading = false
        this.adultApi.isError = true
        this.adultApi.error = e
      }
      this.adultApi.callbackSuccess = (resp) => {
        this.adultApi.isLoading = false
        this.$refs['bread_table'].fetch()
        console.log(resp)
      }
      //EOC
      },
      mounted() {
        //
      },
      methods: {
        sendLeague(){
          this.$api.fetch(this.api)
        },
        sendLeagueToAdult(){
          this.$api.fetch(this.adultApi)
        }
      }
    }
  </script>