<template>
<v-card class="mb-3">
  <v-simple-table dense>
    <thead>
      <tr>
        <th></th>
        <th class="text-right">{{$t('view.PageMainEventScoreboardReport.header.event')}}</th>
        <th class="text-right">{{$t('view.PageMainEventScoreboardReport.header.school')}}</th>
        <th class="text-right">{{$t('view.PageMainEventScoreboardReport.header.comment')}}</th>
      </tr>
    </thead>
    <tbody>
      <template>
        <tr v-for="(d, i) in [
          'students',
          'participants',
          'finishers',
          'attendance',
          'completed',
          'submissions',
          'questions',
          'corrects',
          'accuracy',
          'averageQuestion',
        ]"  :key="i">
          <td>{{ $t(`view.PageMainEventScoreboardReport.stat.${d}`) }}</td>
          <td class="text-right">{{ (typeof api.data[d] != 'undefined') ? api.data[d] : "・・・ " }}<span v-if="d == 'attendance' || d == 'completed' || d == 'accuracy'">%</span></td>
          <td class="text-right">{{ (typeof api2.data[d] != 'undefined') ? api2.data[d] : "・・・ " }}<span v-if="d == 'attendance' || d == 'completed' || d == 'accuracy'">%</span></td>
          <td class="text-right">
            <div v-if="(typeof api.data[d] != 'undefined' && typeof api2.data[d] != 'undefined') && (d == 'attendance' || d == 'completed' || d == 'accuracy' || d == 'averageQuestion')">
              <b v-if="Number(api.data[d]) < Number(api2.data[d])" class="green--text text--darken-3 text-uppercase">{{$t('view.PageMainEventScoreboardReport.comment.high')}}</b>
              <b v-if="Number(api.data[d]) > Number(api2.data[d])" class="grey--text text--darken-1 text-uppercase">{{$t('view.PageMainEventScoreboardReport.comment.low')}}</b>
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </v-simple-table>
</v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    "group","eventCode"
  ],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
      data: {},
    },
    //EOC
    //BOC:[api2]
    api2: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
      data: {},
    },
    //EOC
  }),
  created() {
    //BOC:[api]
    this.api.url = `${this.$api.servers.analysis}/api/v1/zh/report/event/matrix`;
    this.api.method = "post";
    this.api.params = {
      institutionId: null,
      eventCode: this.eventCode,
    };
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.data = resp
      this.api.isLoading = false;
    }
    //EOC
    //BOC:[api2]
    this.api2.url = `${this.$api.servers.analysis}/api/v1/zh/report/event/matrix`;
    this.api2.method = "post";
    this.api2.params = {
      institutionId: this.group &&  this.group.Institution ? this.group.Institution.id : null,
      eventCode: this.eventCode,
    };
    this.api2.callbackReset = () => {
      this.api2.isLoading = true;
      this.api2.isError = false;
    };
    this.api2.callbackError = (e) => {
      this.api2.isLoading = false;
      this.api2.isError = true;
      this.api2.error = e;
    };
    this.api2.callbackSuccess = (resp) => {
      this.api2.data = resp
      this.api2.isLoading = false;
    }
    //EOC
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$api.fetch(this.api);
      if(this.group && this.group.Institution) this.$api.fetch(this.api2);
    },
  },
};
</script>