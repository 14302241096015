<template>
  <div>
    <div class="d-flex my-3">
      <v-spacer></v-spacer>
      <v-btn @click="generateAll" :loading="generateApi.isLoading" color="primary" class="mr-3">
       Generate to All
      </v-btn>
      <v-btn @click="printThis" :loading="loading" color="primary" class="mr-3">
       Download Image
      </v-btn>
      <JsonExcel name="event_report.xls" worksheet="Hamochi" :data="printData">
        <v-btn color="primary" dark>
          Download Excel
        </v-btn>
      </JsonExcel>
    </div>
    <div ref="printMe">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">#</th>
              <th class="text-left">School</th>
              <th class="text-left">Code</th>
              <th class="text-left">Type</th>
              <th class="text-left">State</th>
              <th class="text-left">Participate</th>
              <th class="text-left">Students</th>
              <th class="text-left">Players</th>
              <th class="text-left">Participants</th>
              <th class="text-left">Finishers</th>
              <th class="text-left">Attendance</th>
              <th class="text-left">Completion</th>
              <th class="text-left">Questions</th>
              <th class="text-left">Corrects</th>
              <th class="text-left">Accuracy</th>
              <th class="text-left">Average Questions</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, a) in data" :key="item.id">
              <td>{{ a + 1 }}</td>
              <td>
                <router-link
                  class="text-decoration-none"
                  :to="{ name: 'PageModeratorInstitutionRead',params:{id:item.id} }"
                  >{{ item.name }}</router-link
                >
              </td>
              <td>{{ item.groupCode }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.state }}</td>
              <td><v-chip 
  :color="item.isParticipating ? 'success' : 'error'" 
  dark
  small
>{{item.isParticipating?'Yes':'No'}}</v-chip></td>
              <td>{{ item.students }}</td>
              <td>{{ item.players }}</td>
              <td>{{ item.participants }}</td>
              <td>{{ item.finishers }}</td>
              <td>{{ item.attendance }}%</td>
              <td>{{ item.completed }}%</td>
              <td>{{ item.questions }}</td>
              <td>{{ item.corrects }}</td>
              <td>{{ item.accuracy }}%</td>
              <td>{{ item.averageQuestion }}%</td>
              <td class="text-left" width="120px">
                <ProgressBar
                  :completed="item.completed"
                  :attendance="item.attendance"
                ></ProgressBar>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProgressBar from "./ProgressBar.vue";
import JsonExcel from "vue-json-excel";
//BOC: [print]
import VueHtml2Canvas from "vue-html2canvas";
import Vue from "vue";
Vue.use(VueHtml2Canvas);
//EOC
export default {
  components: {
    ProgressBar,
    JsonExcel
  },
  computed: mapState({
    //
  }),
  props: ["eventCode"],
  data: () => ({
    printData: [],
    data: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    generateApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    loading: false,
  }),
  created() {
    //BOC:[api]
    this.api.url = `${this.$api.servers.analysis}/api/v1/zh/moderator/eventReport/${this.eventCode}`;
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.data = resp;
      this.api.isLoading = false;
      this.printData = this.data.map((obj,index) => {
        return { 'No': index+1,
        'Name': obj.name,
        'Code': obj.groupCode,
        'Type': obj.type,
        'State': obj.state,
        'Participate': obj.isParticipating ? 'Yes' : 'No',
        'Students':obj.students,
        'Players': obj.players,
        'Participants': obj.participants,
        'Finishers': obj.finishers,
        'Attendance (%)':obj.attendance,
        'Completion (%)': obj.completed,
        'Questions': obj.questions,
        'Corrects': obj.corrects,
        'Accuracy': obj.accuracy,
        'averageQuestion': obj.averageQuestion,
       };
      });
    };
    //EOC

    this.generateApi.url = `${this.$api.servers.analysis}/api/v1/zh/moderator/eventReport/generateAll`;
    // this.generateApi.method = "get";
    this.generateApi.callbackReset = () => {
      this.generateApi.isLoading = true;
      this.generateApi.isError = false;
    };
    this.generateApi.callbackError = (e) => {
      this.generateApi.isLoading = false;
      this.generateApi.isError = true;
      this.generateApi.error = e;
    };
    this.generateApi.callbackSuccess = () => {
     
      this.generateApi.isLoading = false;
      this.$api.fetch(this.api);
     
    };
    //EOC
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    async printThis() {
      this.loading = true;
      const el = this.$refs.printMe;
      const options = {
        type: "dataURL",
        useCORS: true,
      };
      this.output = await this.$html2canvas(el, options);
      this.downloadImg();
    },
    downloadImg() {
      var link = document.createElement("a");
      link.download = "Hamochi Report.png";
      link.href = this.output;
      link.click();
      this.loading = false;
    },
    generateAll(){
      this.generateApi.params ={eventCode: this.eventCode}
      this.$api.fetch(this.generateApi);
    }
  },
};
</script>