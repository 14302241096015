<template>
<v-card class="mb-3">
  <v-card-title>
    <span v-if="isUnique">Daily Students</span>
    <span v-else>Daily Submissions</span>
  </v-card-title>
  <div v-if="series[0].data.length > 0">
    <VueApexCharts
      height="300px"
      :options="options"
      :series="series"
    ></VueApexCharts>
  </div>
  <v-card-text v-else>
    <ALoader :isLoading="true"/>
  </v-card-text>
</v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapState } from "vuex";
export default {
  components: {
    VueApexCharts,
  },
  computed: mapState({
    //
  }),
  props: [
    "group",
    "isUnique",
    "eventCode"
  ],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
      data: {},
    },
    //EOC
    //BOC:[chart]
    options: {
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        title:{
          text:"Day",
        },
        labels: {
          style: {
            fontSize: "10px",
          },
        },
        max:14,
        categories: [],
      },
      yaxis: [
        {
          title: {
            text: "School",
          }
        },
        {
          opposite: true,
          title: {
            text: "All",
          }
        },
      ],
    },
    series: [
      {
        name:"School",
        type: 'column',
        data: [],
      },
      {
        name:"All",
        type: 'line',
        data: [],
      },
    ],
    //EOC
  }),
  created() {
    //BOC:[api]
    this.api.url = `${this.$api.servers.analysis}/api/v1/zh/report/event/submission`;
    if(this.isUnique) this.api.url += "/unique"
    this.api.method = "post";
    this.api.params = {
      institutionId: this.group &&  this.group.Institution ? this.group.Institution.id : null,
      eventCode: this.eventCode,
    };
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.data = resp
      this.api.isLoading = false;
      var dataY = Object.values(resp.all)
      var dataYSchool = Object.values(resp.school)
      //this.options.yaxis.max = Math.ceil(Math.max(...dataY)/1000)*1000
      this.series[1].data = dataY
      this.series[0].data = dataYSchool
      console.log(resp)
    }
    //EOC
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$api.fetch(this.api);
    },
  },
};
</script>