<template>
  <v-container>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
     <!-- BOC:[header] -->
     <div class="d-flex pb-6 text-right">
      <v-spacer></v-spacer>
      <div>
      <JsonExcel name="delivery_user_info.xls" worksheet="Hamochi" :data="printData">
        <v-btn color="primary" dark>
          {{ $t("action.download") }}
        </v-btn>
      </JsonExcel>
    </div>
    </div>
    <!-- EOC -->
  
    <v-card v-if="!api.isError" :loading="api.isLoading">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">Parent's name</th>
              <th class="text-left">Institution Name</th>
              <th class="text-left">Address</th>
              <th class="text-left">Postcode</th>
              <th class="text-left">Town</th>
              <th class="text-left">State</th>
              <th class="text-left">Full Address</th>
              <th class="text-left">Parent's mobile</th>
              <th class="text-left">Child's name</th>
              <th class="text-left">Child's mochiId</th>
            </tr>
          </thead>
          <tbody class="text-uppercase">
            <tr v-for="(item, i) in data" :key="item.id">
              <td>{{ i + 1 }}</td>
              <td>{{ item.ownerName }}</td>
              <td>{{ item.Institution.name }}</td>
              <td>{{ item.Institution.address }}</td>
              <td>{{ item.Institution.postcode }}</td>
              <td>{{ item.Institution.town }}</td>
              <td>{{ item.Institution.state }}</td>
              <td>{{ item.ownerName }}<br/>{{item.Institution.name}}<br/>{{ item.Institution.address }}<br/>{{ item.Institution.postcode }} {{ item.Institution.town }}<br/>{{ item.Institution.state }}<br/>{{ item.mobile }}</td>
              <td>{{ item.mobile }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.mochiId }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import JsonExcel from "vue-json-excel";
export default {
  props: ["url"],
  components: {
    JsonExcel,
  },
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    printData: [],
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.printData = this.data.map((obj) => {
        return { "Parent's name": obj.ownerName,
        "Institution Name": obj.Institution.name,
        'Address':obj.Institution.address,
        'Town': obj.Institution.town,
        'Postcode': "'"+obj.Institution.postcode,
        'State': obj.Institution.state,
        "Parent's mobile": obj.mobile,
        "Child's name": obj.name,
        "Child's mochiId": obj.mochiId
       };
      });
    };
    //EOC
  },
  methods: {
    fetch() {
      this.api.url = this.url;
      this.$api.fetch(this.api);
    },
  },
  beforeMount() {
    this.fetch();
  },
};
</script>

<style>
</style>