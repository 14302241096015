<template>
  <div>
    <!-- BOC:[header] -->
    <v-row class="mb-3">
      <v-col class="text-right">
        <v-btn
          color="primary"
          large
          :to="{name:'PageModeratorEventEventSponsorAdd',params:{parentId:parent.id,parent:parent}}"
        >
          + New Sponsor
        </v-btn>
      </v-col>
    </v-row>
    <!-- EOC -->
    <!-- BOC:[table] -->
    <BreadBrowseTable
      role="Moderator"
      :model="model"
      :url="`${$api.servers.event}/api/v1/en/moderator/event/${parent.id}/sponsor`"
      :isSearchable="true"
      :parent="parent"
      :modelParent="modelParent"
    ></BreadBrowseTable>
    <!-- EOC -->
  </div>
  </template>
  
  <script>
    //BOC:[model]
    import modelParent from '@/models/items/event'
    import model from '@/models/items/eventSponsor'
    //EOC
    //BOC:[table]
    import BreadBrowseTable from '@/components/Bread/BreadBrowseTable'
    //EOC
    import { mapState } from 'vuex'
    export default {
      components:{
        //BOC:[table]
        BreadBrowseTable,
        //EOC
      },
      computed: mapState({
        school: state => state.school.data,
      }),
      props:[
        'role',
        'parent',
      ],
      data:()=>({
        //BOC:[model]
        modelParent:modelParent,
        model: model,
        //EOC
      }),
      created() {
        //
      },
      mounted() {
        //
      },
      methods: {
        //
      }
    }
  </script>