var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-3"},[_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t('view.PageMainEventScoreboardReport.header.event')))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t('view.PageMainEventScoreboardReport.header.school')))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t('view.PageMainEventScoreboardReport.header.comment')))])])]),_c('tbody',[_vm._l(([
          'students',
          'participants',
          'finishers',
          'attendance',
          'completed',
          'submissions',
          'questions',
          'corrects',
          'accuracy',
          'averageQuestion' ]),function(d,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(_vm.$t(("view.PageMainEventScoreboardReport.stat." + d))))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s((typeof _vm.api.data[d] != 'undefined') ? _vm.api.data[d] : "・・・ ")),(d == 'attendance' || d == 'completed' || d == 'accuracy')?_c('span',[_vm._v("%")]):_vm._e()]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s((typeof _vm.api2.data[d] != 'undefined') ? _vm.api2.data[d] : "・・・ ")),(d == 'attendance' || d == 'completed' || d == 'accuracy')?_c('span',[_vm._v("%")]):_vm._e()]),_c('td',{staticClass:"text-right"},[((typeof _vm.api.data[d] != 'undefined' && typeof _vm.api2.data[d] != 'undefined') && (d == 'attendance' || d == 'completed' || d == 'accuracy' || d == 'averageQuestion'))?_c('div',[(Number(_vm.api.data[d]) < Number(_vm.api2.data[d]))?_c('b',{staticClass:"green--text text--darken-3 text-uppercase"},[_vm._v(_vm._s(_vm.$t('view.PageMainEventScoreboardReport.comment.high')))]):_vm._e(),(Number(_vm.api.data[d]) > Number(_vm.api2.data[d]))?_c('b',{staticClass:"grey--text text--darken-1 text-uppercase"},[_vm._v(_vm._s(_vm.$t('view.PageMainEventScoreboardReport.comment.low')))]):_vm._e()]):_vm._e()])])})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }