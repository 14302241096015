<template>
  <v-row>
    <v-col
      ><v-select
        dense
        :label="$t('model.name.classroom')"
        :placeholder="$t('model.name.classroom')"
        class="white"
        :items="classroom"
        item-text="name"
        outlined
        v-model="item"
        item-value="id"
      ></v-select
    ></v-col>
  </v-row>
</template>

<script>
export default {
  props: ["callbackSelect", "institutionId","schoolYear"],
  data: () => ({
    classroom:[],
    id: null,
    item: 0,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  watch: {
    item: function (val) {
      this.callbackSelect(val);
    },
  },
  created() {
    this.api.method = "get";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/data/institution/" +
      this.institutionId +
      "/classroom";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      var classroom = resp;
      if(this.schoolYear == 0){
        this.classroom = classroom
      }else{
        this.classroom = this.$_.filter(classroom, ['schoolYear', this.schoolYear]);
      }
      this.classroom.push({
        id: 0,
        name: this.$t("model.filterBy.all"),
      });
      this.classroom.sort((a, b) => (a.schoolYear > b.schoolYear ? 1 : -1));
      this.item = 0
    };
  },
  methods:{
    fetch(){
      this.$api.fetch(this.api);
    }
  },
  mounted() {
  
  },
};
</script>

<style>
</style>