<template>
  <div>
    <v-progress-linear
      color="green"
      height="12"
      v-model="completed"
      :buffer-value="attendance"
    ></v-progress-linear>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: ["completed", "attendance"],
  data: () => ({
    value: 10,
    bufferValue: 100,
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>