<template>
  <div>
     <!-- BOC:[tabs] -->
     <v-tabs
      v-model="tab"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-tab key="all" :href="`#all`">
       All
      </v-tab>
      <v-tab key="schoolYear" :href="`#schoolYear`">
       By School Year
      </v-tab>
      <v-tab key="institution" :href="`#institution`">
        Institution
      </v-tab>
    </v-tabs>
    <!-- EOC -->
    <!-- BOC:[tab] -->
    <v-tabs-items v-model="tab">
      <v-tab-item key="all" value="all" class="px-1 py-2">
        <!-- BOC:[table] -->
        <PageReportAll v-if="!groupApi.isLoading" :eventCode="eventCode" />
        <!-- EOC -->
      </v-tab-item>
      <v-tab-item key="schoolYear" value="schoolYear" class="px-1 py-2">
        <!-- BOC:[table] -->
        <PageReportBySchoolYear v-if="!groupApi.isLoading" :eventCode="eventCode" />
        <!-- EOC -->
      </v-tab-item>
      <v-tab-item key="school" value="school" class="px-1 py-2">
        <div v-if="!isOngoing">
      <FilterInstitution :callbackSelect="selectInstitution"  />
      <PageReportInstitution v-if="!groupApi.isLoading" :group="group" :eventCode="eventCode" />
    </div>
    <div v-else class="text-center pa-3">
      Report will be available when event ended.
    </div>
      </v-tab-item>
      <!-- EOC -->
    </v-tabs-items>
    <!-- EOC -->
    
  </div>
</template>

<script>
//BOC:[table]
//import PageParticipant from "@/components/Bread/Event/Student/PageParticipant";
//EOC
import { mapState } from "vuex";
import PageReportInstitution from "../components/Moderator/Event/Report/PageReport.vue";
import PageReportAll from "../components/Moderator/Event/Report/PageReportAll.vue";
import PageReportBySchoolYear from "../components/Moderator/Event/Report/PageReportBySchoolYear.vue";
import FilterInstitution from "@/components/Moderator/Event/Report/FilterInstitution";
export default {
  components: {
    PageReportInstitution,
    FilterInstitution,
    PageReportAll,
    PageReportBySchoolYear
},
  computed: mapState({}),
  props: ["role", "parent"],
  data: () => ({
    tab:null,
    isOngoing:true,
    groupCode:null,
    eventCode:"",
    group:{},
    groupApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  created() {
    this.groupApi.method = "get";
    this.groupApi.callbackReset = () => {
      this.groupApi.isLoading = true;
      this.groupApi.isError = false;
    };
    this.groupApi.callbackError = (e) => {
      this.groupApi.isLoading = false;
      this.groupApi.isError = true;
      this.groupApi.error = e;
    };
    this.groupApi.callbackSuccess = (resp) => {
      this.groupApi.isLoading = false;
      this.group = resp;
      this.groupCode = this.group.Group.code
   
    };
  },
  mounted() {
    if(this.parent && this.parent.code){
      this.eventCode = this.parent.code
    }
    this.checkOngoing()
  },
  methods: {
    fetchGroup(code) {
      this.groupApi.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/group/" +
       code;
      this.$api.fetch(this.groupApi);
    },
    selectInstitution(code) {
      console.log(code)
      this.groupCode = code;
        this.fetchGroup(this.groupCode);
    },
    checkOngoing() {
      if (this.$moment() > this.$moment(this.parent.timestampEnd)) {
       this.isOngoing =  false
      } else {
        this.isOngoing =  true
      }
    },
  },
 
};
</script>