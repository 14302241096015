<template>
  <v-container>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <v-card v-if="!api.isError" :loading="attemptApi.isLoading">
      <Student
      v-if="!attemptApi.isLoading"
        :role="role"
        :filterStudents="filterStudents"
        :classroom="classroom"
      />
    </v-card>
  </v-container>
</template>

<script>
import Student from "@/components/Bread/Event/Student/Student";
export default {
  props: ["url", "role"],
  components: {
    Student,
  },
  data: () => ({
    student: [],
    participant: [],
    filterStudents: [],
    classroom: [],
    Attempt:[],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    attemptApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: {},
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";
    this.attemptApi.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.attemptApi.callbackReset = () => {
      this.attemptApi.isLoading = true;
      this.attemptApi.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.attemptApi.callbackError = (e) => {
      this.attemptApi.isLoading = false;
      this.attemptApi.isError = true;
      this.attemptApi.error = e;
    };
    this.attemptApi.callbackSuccess = (resp) => {
      this.Attempt = resp
      this.attemptApi.isLoading = false;
      this.filter();
    }
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.student = this.data.ClassroomOnEvent;
      this.participant = this.data.Participant;
      this.fitchAttempt(this.data.id);
    };
    //EOC
  },
  methods: {
    fetch() {
      this.api.url = this.url;
      this.$api.fetch(this.api);
    },
    fitchAttempt(id) {
      this.attemptApi.url =
        this.$api.servers.log +
        "/log/v1/" +
        this.$_getLocale() +
        "/attempt/event/" +
        id;
      this.$api.fetch(this.attemptApi);
    },
    filter() {
      for (var i = 0, l = this.student.length; i < l; i++) {
        this.classroom.push({
          name:
            this.student[i].Classroom.name +
            " - " +
            this.student[i].Classroom.School.name,
          id: this.student[i].Classroom.id,
        });
        for (
          var j = 0, k = this.student[i].Classroom.Student.length;
          j < k;
          j++
        ) {
          this.filterStudents.push({
            Student: {
              id: this.student[i].Classroom.Student[j].id,
              avatar: this.student[i].Classroom.Student[j].avatar,
              Classroom: {
                name: this.student[i].Classroom.Student[j].Classroom.name,
                id: this.student[i].Classroom.Student[j].Classroom.id,
              },
              School: {
                name: this.student[i].Classroom.Student[j].School.name,
                id: this.student[i].Classroom.Student[j].School.id,
              },
            },
            name: this.student[i].Classroom.Student[j].name,
            totalStar: 0,
            totalAttempt: 0,
            totalScore: 0,
            participant: false,
            status: 0,
            Attempt:[]
          });
        }
      }
      for (var b = 0, m = this.participant.length; b < m; b++) {
        var id = this.participant[b].id;
          var attempt = this.Attempt.filter(function (entry) {
          return entry.participantId == id;
        });
        this.participant[b].Attempt = attempt
        for (var a = 0, n = this.filterStudents.length; a < n; a++) {
          if (
            this.participant[b].Student.id == this.filterStudents[a].Student.id
          ) {
            this.filterStudents.splice(a, 1);
            this.participant[b].status = 1;
            this.filterStudents.push(this.participant[b]);
          }
        }
      }
      this.filterStudents.sort((a, b) => (a.status < b.status ? 1 : -1));
      this.filterStudents
        .reverse()
        .sort((a, b) => (a.totalScore < b.totalScore ? 1 : -1));
    },
  },
  beforeMount() {
    this.fetch();
  },
};
</script>

<style>
</style>