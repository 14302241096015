<template>
  <v-row>
    <v-col
      ><v-select
        dense
        :label="$t('action.orderBy')"
        :placeholder="$t('action.orderBy')"
        class="white"
        :items="items"
        item-text="by"
        outlined
        v-model="value"
        item-value="value"
      ></v-select
    ></v-col>
  </v-row>
</template>

<script>
export default {
  props: ["callbackSelect"],
  data: () => ({
    value: "2",
    items: [],
  }),
  created() {
    this.items = [
      {
        value: "2",
        by: this.$t("model.prop.totalScore"),
      },
      {
        value: "1",
        by: this.$t("model.prop.name"),
      },
    ];
  },
  watch: {
    value: function (val) {
      this.value = val;
      this.callbackSelect(val);
    },
  },
};
</script>

<style>
</style>